import { GetServerSidePropsContext } from "next";
import api from "./api";
import { Section } from "./section-model";

export type { Section };

export default function loadCategories(
  companySlug: string,
  unpublished = false,
  context?: GetServerSidePropsContext
) {
  return api<Section[] | undefined>({
    path: `/helpcenter-public/categories/${companySlug}`,
    query: unpublished ? { include_unpublished: true } : undefined,
    context,
  }).then((categories) => categories?.sort((a, b) => a.order - b.order));
}
