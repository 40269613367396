import stringifyQuery, { Query } from "./stringify-query";

const patchURLWithQuery = (path: string, query?: Query) =>
  query
    ? `${path}${path.includes("?") ? "&" : "?"}${stringifyQuery(query)}`
    : path;

export default function http({
  method = "GET",
  url,
  query,
  body,
  headers,
  mode,
}: Props): Promise<Response> {
  return fetch(patchURLWithQuery(url, query), {
    method,
    headers,
    body,
    mode,
  });
}

export type Method = "GET" | "POST" | "PUT" | "DELETE";

export type Body =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null;

export type Props = {
  url: string;
  query?: Query;
  method?: Method;
  headers?: HeadersInit;
  mode?: "cors" | "no-cors" | "same-origin";
  body?: Body;
};

export type { Query };
