import t from "../texts/t";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

export default function formatDate(
  date: string | number | Date,
  language?: string
) {
  if (typeof date === "string") date = Date.parse(date);
  if (typeof date === "number") date = new Date(date);
  return `${t(
    months[date.getMonth()],
    language
  )} ${date.getDate()}, ${date.getFullYear()}`;
}
