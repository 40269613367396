const ATLAS_IDENTITY_COOKIE_KEY = "atlasIdentity";
const ATLAS_USER_HASH_COOKIE_KEY = "atlasUserHash";

export default function getAtlasIdentityCookie(appId: string, cookie?: string) {
  const cookies =
    cookie?.split(";") ||
    (typeof document !== "undefined" && document.cookie.split(";")) ||
    [];

  let customerId = "";
  let userHash = "";

  for (const c of cookies) {
    const trimmedCookie = c.trim();
    if (trimmedCookie.startsWith(`${ATLAS_IDENTITY_COOKIE_KEY}${appId}`)) {
      customerId = trimmedCookie.split("=", 2)[1];
    }

    if (trimmedCookie.startsWith(`${ATLAS_USER_HASH_COOKIE_KEY}${appId}`)) {
      userHash = trimmedCookie.split("=", 2)[1];
    }

    if (customerId && userHash) {
      break;
    }
  }

  return { customerId, userHash };
}
