import { getAtlasIdentityFromRequest } from "./atlas-customer-context/identify";
import { AppSettings } from "./get-app-settings";
import { GetServerSidePropsContext } from "next";
import api from "./api";
import { Article } from "./article-model";
import { Section } from "./section-model";
import { uniq as _uniq, union as _union } from "lodash-es";
const ANONYMOUS = "00000000-0000-0000-0000-000000000000";
import {
  getHCSessionIdFromCookies,
  setHCSessionIdToCookies,
} from "./get-set-hc-session-id";

type THCSessionResponse = {
  id: string;
  createdAt: string;
  customerId: string;
  sessionId: string | null;
  lastEventTime: string | null;
  isActive: boolean;
};

type THCSessionDetails = {
  id: string | null;
  expiresAt: number | null;
  userId: string;
};
/**
 * This is the Help Center session tracking
 * and not related to the Atlas Session Recording
 * Assumptions based on product requirements:
 * https://www.notion.so/atlas-support/Reporting-Help-Center-1a0782ad96fe40beb19541e1d3d14335?pvs=4#53535437b43e4fe2b8dae3dd028a320e
 */
const MAX_ACTIVE_SESSION_DURATION = 60 * 60 * 1000; // 60 minutes
const SESSION_ID_KEY_PREFIX = "atlas-hc-session-id-";

function saveSessionDetailsToLocalStorage(
  appSettings: AppSettings,
  sessionDetails: THCSessionDetails
) {
  const key = `${SESSION_ID_KEY_PREFIX}${appSettings.appId}`;
  const value = JSON.stringify(sessionDetails);
  window.localStorage.setItem(key, value);
}

function getSessionDetailsFromLocalStorage(
  appSettings: AppSettings
): THCSessionDetails | null {
  const key = `${SESSION_ID_KEY_PREFIX}${appSettings.appId}`;
  const value = window.localStorage.getItem(key);
  if (!value) return null;
  const { id, expiresAt, userId } = JSON.parse(value);
  return {
    id: id,
    expiresAt,
    userId,
  };
}

function getExpiryTime(data: THCSessionResponse) {
  const createdAtEpoch = new Date(data.createdAt).getTime();
  const lastEventTimeEpoch = data.lastEventTime
    ? new Date(data.lastEventTime).getTime()
    : createdAtEpoch;
  const expiresAt = lastEventTimeEpoch + MAX_ACTIVE_SESSION_DURATION;
  return expiresAt;
}

async function getSessionDetailsFromRequest(
  context: GetServerSidePropsContext,
  appSettings: AppSettings
): Promise<THCSessionDetails> {
  // this session id is unlike the atlas recording session id, as in it does not get destroyed
  // with user loading something in a new tab
  // we only chek expires at whenever the page is loaded
  // the actual session can be longer than the expiresAt, we don't care

  const atlasIdentity = getAtlasIdentityFromRequest(context.req, appSettings);
  const { companyId, atlasId } = atlasIdentity;
  const userId = atlasId || ANONYMOUS;
  // const sessionKey = `${SESSION_ID_KEY_PREFIX}${appId}`;
  // TODO: call the API to create a new session
  // call with the userId, we will handle the anonymous user case
  // on the server side, sort of like we do for the atlas identity
  // this we will first save to the local storage and then return

  const _sessionId = getHCSessionIdFromCookies(context);
  if (_sessionId && atlasId) {
    try {
      await identifySessionUser(_sessionId, atlasId);
    } catch (err) {
      console.log(err);
    }
  }

  return api<THCSessionResponse>({
    path: `/helpcenter-public/sessions/${companyId}/${userId}`,
    method: "GET",
  })
    .then((data) => {
      const _data = data;
      const { id, customerId } = _data;
      const expiresAt = getExpiryTime(_data);
      setHCSessionIdToCookies(context, id);
      return {
        id,
        expiresAt,
        userId: customerId,
      } as THCSessionDetails;
    })
    .catch((err) => {
      console.log(err);
      return {
        id: null,
        expiresAt: null,
        userId: atlasId,
      } as THCSessionDetails;
    });
}

async function identifySessionUser(
  hcSessionId: string | null,
  atlasId: string | null
): Promise<THCSessionDetails | undefined> {
  if (atlasId && hcSessionId) {
    return api({
      path: `/helpcenter-public/sessions/${hcSessionId}/identify/${atlasId}`,
      method: "POST",
    })
      .then((data) => {
        const _data = data as THCSessionResponse;
        const { id, customerId } = _data;
        const expiresAt = getExpiryTime(_data);
        return {
          id,
          expiresAt,
          userId: customerId,
        } as THCSessionDetails;
      })
      .catch((err) => {
        console.log(err);
        return {
          id: null,
          expiresAt: null,
          userId: atlasId,
        } as THCSessionDetails;
      });
  }
}

function _extractArticlesCategoryIds(
  articles: Article[],
  categories: Section[]
) {
  const _articleIds = articles.map((article) => article.id);
  const _categoryIds =
    _union(
      categories.map((category) => category.id),
      articles.map((article) => article.categoryId)
    ) || [];
  return {
    articleIds: _uniq(_articleIds),
    categoryIds: _uniq(_categoryIds),
  };
}

async function trackSessionEvent(
  event:
    | "VOTED"
    | "SEARCHED"
    | "SEARCHED_NO_RESULTS"
    | "SEARCHED_CLICKED"
    | "VIEWED_ARTICLE"
    | "VIEWED_CATEGORY",
  hcSessionDetails: THCSessionDetails,
  pageUrl: string | null,
  articles: Article[],
  categories: Section[],
  meta: Record<string, unknown>
) {
  const { articleIds, categoryIds } = _extractArticlesCategoryIds(
    articles,
    categories
  );
  const body = {
    name: event,
    payload: {
      articles: articleIds,
      categories: categoryIds,
      ...meta,
    },
    url: pageUrl,
  };
  return await _trackSessionEvent(hcSessionDetails, body);
}

type THCSessionEventBody = {
  name: string;
  payload: Record<string, unknown>;
  url: string | null;
};
async function _trackSessionEvent(
  hcSessionDetails: THCSessionDetails,
  body: THCSessionEventBody
) {
  if (!hcSessionDetails.id) return;

  return api({
    path: `/helpcenter-public/sessions/${hcSessionDetails.id}/events`,
    method: "POST",
    body: body,
  })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export {
  getSessionDetailsFromRequest,
  getSessionDetailsFromLocalStorage,
  saveSessionDetailsToLocalStorage,
  identifySessionUser,
};
export type { THCSessionDetails };

export default trackSessionEvent;
