import { GetServerSidePropsContext } from "next";
import api from "./api";
import { Article } from "./article-model";

export type { Article };

export default function searchArticles(
  companySlug: string,
  query: string,
  sort: string = "",
  limit = 100,
  page = 0,
  context?: GetServerSidePropsContext
) {
  return api<Article[]>({
    path: `/helpcenter-public/search/${companySlug}`,
    query: { q: query, sort, page, size: limit },
    context,
  });
}
