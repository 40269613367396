import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import UserInitialsAvatar from "@atlas/uicorn/lib/Display/UserInitialsAvatar";
import User from "../models/user";

const UserAvatar = function UserAvatar({ user, size = 22 }: TUserAvatarProps) {
  const { id, firstName, lastName, profileUrl } = user;

  const styles = useMemo<React.CSSProperties | undefined>(() => {
    if (typeof size !== "number") return;
    const sizeInPx = `${size}px`;

    return {
      width: sizeInPx,
      height: sizeInPx,
      lineHeight: sizeInPx,
    };
  }, [size]);

  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = useCallback(() => setLoaded(true), []);
  useEffect(() => setLoaded(false), [profileUrl]);

  return (
    <SUserAvatarWrapper style={styles}>
      <SImage
        loading="lazy"
        src={profileUrl}
        onLoad={handleImageLoad}
        $loaded={loaded}
      />
      {!loaded && (
        <UserInitialsAvatar
          firstName={firstName}
          lastName={lastName}
          size={size}
          id={id}
        />
      )}
    </SUserAvatarWrapper>
  );
};

export default UserAvatar;

// #region Styles

const SImage = styled.img<{ $loaded: boolean }>`
  background-color: ${(p) => p.theme.color.grey0};
  position: absolute;
  transition: opacity 0.2s ease-out, transform 0.2s ease;
  opacity: 0;
  transform: scale(0.7) rotate(-30deg);
  z-index: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  ${(p) =>
    p.$loaded
      ? css`
          opacity: 1;
          transform: scale(1) rotate(0);
        `
      : css`
          height: 0;
        `}
`;

const SUserAvatarWrapper = styled.span`
  background-color: ${(p) => p.theme.color.grey0};
  display: flex;
  position: relative;
  border-radius: 50%;
`;

type TUserAvatarProps = Omit<React.HTMLAttributes<HTMLElement>, "children"> & {
  user: User;
  size?: number;
};
