import getAtlasIdentityCookie from "./get-atlas-identity-cookie";

export default function getAuthHeaders(appId: string, cookie?: string) {
  const { customerId, userHash } = getAtlasIdentityCookie(appId, cookie);

  return {
    "x-atlas-account-id": appId,
    ...(customerId && { "x-atlas-customer-id": customerId }),
    ...(userHash && { "x-atlas-user-hash": userHash }),
  };
}
