import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const PersonIcon = React.memo(function PersonIcon(props: TPersonIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Person" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.55032 19.1854C4.56445 19.1613 4.57284 19.1335 4.57353 19.1039C4.6165 17.4453 5.28036 15.892 6.45556 14.7146C7.66943 13.4965 9.28505 12.8262 11.0038 12.8262C12.222 12.8262 13.3907 13.1635 14.3983 13.7951C14.4242 13.8114 14.4539 13.8205 14.4845 13.8217C14.4847 13.8217 14.4849 13.8217 14.4851 13.8217C16.2687 14.9692 17.45 16.9716 17.45 19.25H19C19 16.9045 17.9906 14.7948 16.3826 13.3315C16.3994 13.3052 16.3983 13.2682 16.3706 13.243C15.7115 12.6515 14.9586 12.1737 14.1427 11.8293C14.1384 11.8271 14.1341 11.8255 14.1298 11.8239C14.1255 11.8223 14.1212 11.8207 14.1169 11.8186C15.4511 10.8496 16.319 9.2748 16.319 7.49805C16.319 4.55469 13.9386 2.16992 11.0017 2.16992C8.06474 2.16992 5.68642 4.55469 5.68642 7.49805C5.68642 9.2748 6.55439 10.8496 7.89072 11.8186C7.88642 11.8207 7.88212 11.8223 7.87782 11.8239C7.87353 11.8255 7.86923 11.8271 7.86493 11.8293C6.90458 12.2354 6.04306 12.8176 5.30185 13.5609C4.56492 14.2965 3.97823 15.1685 3.5745 16.1283C3.17729 17.0682 2.96291 18.0752 2.94286 19.0953C2.94229 19.1182 2.94631 19.1411 2.95469 19.1624C2.96307 19.1838 2.97564 19.2032 2.99166 19.2196C2.99436 19.2224 2.99716 19.2251 3.00003 19.2277C3.00001 19.2351 3 19.2425 3 19.25H3.03148C3.03681 19.253 3.04231 19.2556 3.04796 19.258C3.0691 19.2669 3.0918 19.2715 3.11474 19.2715H4.40165C4.43142 19.2715 4.45963 19.2637 4.48426 19.25H4.55C4.55 19.2285 4.55011 19.2069 4.55032 19.1854ZM8.3956 10.1105C9.09384 10.8088 10.0198 11.1934 11.0038 11.1934C11.9878 11.1934 12.9138 10.8088 13.612 10.1105C14.3081 9.4123 14.6927 8.48418 14.6927 7.49805C14.6938 7.01283 14.5988 6.53219 14.4134 6.08382C14.2279 5.63545 13.9556 5.2282 13.612 4.88555C12.9138 4.1873 11.9899 3.80273 11.0038 3.80273C10.0177 3.80273 9.09169 4.1873 8.3956 4.88555C7.6995 5.58379 7.31493 6.51191 7.31493 7.49805C7.31384 7.98326 7.40876 8.4639 7.59423 8.91227C7.7797 9.36065 8.05205 9.76789 8.3956 10.1105Z"
            />
        </IconBase>
    );
});

export default PersonIcon;

// #region Types

export type TPersonIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function' ? () => <PersonIcon /> : { ...IconBaseExample, render: PersonIcon };

// #endregion
