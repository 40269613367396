import { CompanySettings } from "./load-company-settings";

export default function getBaseUrl(settings: CompanySettings) {
  const url = settings.customDomain
    ? settings.customDomain
    : `${settings.helpCenterUrl || ""}/${settings.slug}`;
  // Wrap in URL() to normalize link to avoid hydration mismatch
  // return new URL(url).toString();
  return url; // Do not normalize yet as it causes more issues
}
