import React, { useMemo } from 'react';
import { Avatar as AntAvatar } from 'antd';
import styled from 'styled-components';
import type { TExample } from '@atlas/uicorn/preview/modules';
import { atlasTheme } from '@atlas/uicorn/lib/System/StylesProvider';
import PersonIcon from '@atlas/uicorn/lib/Icon/PersonIcon';

const UserInitialsAvatar = React.memo(function UserInitialsAvatar({
    id,
    firstName,
    lastName,
    email,
    size = 32,
}: TUserInitialsAvatarProps): React.ReactElement {
    const initials = useMemo(() => abbreviate(firstName, lastName, email), [firstName, lastName, email]);
    const colors = useAvatarColors(firstName, lastName, email, id);
    return (
        <SAvatar
            $background={colors?.background}
            $textColor={colors?.textColor}
            size={size}
            children={initials || <PersonIcon size={size} />}
        />
    );
});

export default UserInitialsAvatar;

// #region Helpers

function slugifyName(name: string) {
    const _slug = name.match(/\b[A-Za-z]/g);
    if (_slug) {
        if (_slug.length > 1) return _slug[0] + _slug[_slug.length - 1];
        if (name.length > 1) return name.slice(0, 2);
        return _slug[0] + _slug[0];
    }
}

function abbreviate(firstName?: string, lastName?: string, email?: string) {
    const name = slugifyName([firstName, lastName].filter(Boolean).join(' '));

    if (name) return name.toUpperCase();
    if (email) return email.slice(0, 2).toUpperCase();
    return '';
}

const paletteColors = Object.keys(atlasTheme.color).filter((name) => /^palette\d+$/.test(name));

function useAvatarColors(firstName?: string, lastName?: string, email?: string, id?: string) {
    return useMemo(() => {
        const hashNumber = (id || [firstName, lastName, email].filter(Boolean).join(''))
            .split('')
            .map((c) => c.charCodeAt(0))
            .reduce((s, v) => s + v, 0);

        const index = hashNumber % paletteColors.length;
        const colorName = paletteColors[index];

        return {
            background: atlasTheme.color[colorName as keyof typeof atlasTheme.color],
            textColor: atlasTheme.color[`${colorName}Inverse` as keyof typeof atlasTheme.color],
        };
    }, [id, firstName, lastName, email]);
}

// #endregion

// #region Styles

export const SAvatar = styled(AntAvatar)<{
    $background?: string;
    $textColor?: string;
}>`
    &.ant-avatar {
        background-color: ${(p) => p.$background || p.theme.color.grey0};
        color: ${(p) => p.$textColor || p.theme.color.grey9};
    }
`;

// #endregion

// #region Types

export type TUserInitialsAvatarProps = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    size?: number;
};

// #endregion

// #region Examples

export const Example: TExample = {
    render(props) {
        return (
            <UserInitialsAvatar
                firstName={props.firstName}
                lastName={props.lastName}
                email={props.email}
                size={props.size}
                id={props.id}
            />
        );
    },
    controls: {
        firstName: { type: 'string', initial: 'John' },
        lastName: { type: 'string', initial: 'Doe' },
        email: { type: 'string', initial: 'example@email.com' },
        size: { type: 'number', initial: 32 },
        id: { type: 'string', initial: '123abcd' },
    },
    handlers: {
        onClick: 'console',
    },
    figma: 'https://www.figma.com/file/fRYzY91MBgJhjJwO17emBQ/Design-System-2.0-%E2%80%93-Web?type=design&node-id=0-1&mode=design&t=Hu2LDZwLvF5nHq01-0',
};

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('UserInitialsAvatar', async () => {
        const testModule = '@test';
        const { render } = await import(/* @vite-ignore */ testModule);

        it('matches snapshot', () => {
            const result = render(<Example.render />);
            expect(result.container).toMatchSnapshot();
        });
    });
}

// #endregion
