export default function normalizeCssSize(value: TCssSize | undefined | null): string | undefined {
    return typeof value === 'number'
        ? `${value / 10}rem`
        : typeof value === 'string'
        ? value.match(/^\d+(\.\d+)?$/)
            ? `${parseFloat(value) / 10}rem`
            : value
        : value ?? undefined;
}

// #region Types

export type TCssSize = number | string;

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('normalizeCssSize', () => {
        it('keeps values with units and complex values', () => {
            expect(normalizeCssSize('1rem')).toBe('1rem');
            expect(normalizeCssSize('1.5rem')).toBe('1.5rem');
            expect(normalizeCssSize('var(--size)')).toBe('var(--size)');
            expect(normalizeCssSize('calc(1rem + 1px)')).toBe('calc(1rem + 1px)');
        });

        it('add rem units to numbers', () => {
            expect(normalizeCssSize(10)).toBe('1rem');
            expect(normalizeCssSize(15)).toBe('1.5rem');
        });

        it('add rem units to string numbers', () => {
            expect(normalizeCssSize('10')).toBe('1rem');
            expect(normalizeCssSize('15')).toBe('1.5rem');
        });
    });
}

// #endregion
