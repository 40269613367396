import type { TDict } from "./t";

const pt: Record<TDict, string> = {
  "Browse by category": "Navegar por categoria",
  "Popular articles": "Artigos populares",
  "No data": "sem dados",
  "Knowledge base of {0}": "Base de conhecimento de {0}",
  "Help Center": "Centro de Ajuda",
  "Customer Portal": "Portal do Cliente",
  "What can we help you find?": "O que podemos ajudar você a encontrar?",
  "Search for articles...": "Pesquisar por artigos...",
  "Search results": "Resultados da pesquisa",
  "Trending articles": "Artigos populares",
  Close: "Fechar",
  "No results": "Sem resultados",
  January: "Janeiro",
  February: "Fevereiro",
  March: "Março",
  April: "Abril",
  May: "Maio",
  June: "Junho",
  July: "Julho",
  August: "Agosto",
  September: "Setembro",
  October: "Outubro",
  November: "Novembro",
  December: "Dezembro",
  "Help Center Search": "Pesquisar no Centro de Ajuda",
  "Search articles in the knowledge base of {0}":
    "Pesquisar artigos na base de conhecimento de {0}",
  "Sort by:": "Ordenar por:",
  '{0} results for "{1}"': '{0} resultados para "{1}"',
  Relevance: "Relevância",
  Date: "Data",
  Title: "Título",
  "Last updated {0}": "Última atualização em {0}",
  "We appreciate your feedback!": "Agradecemos seu feedback!",
  "Was this article helpful?": "Este artigo foi útil?",
  "Vote Up": "Votar para cima",
  "Vote Down": "Votar para baixo",
  "More from {0}": "Mais artigos de {0}",
  "My Tickets": "Meus Tickets",
  "Company tickets": "Tickets da empresa",
  "If you are a customer, login to view your tickets.":
    "Se você é um cliente, faça login para ver seus tickets.",
  Login: "Login",
  "Search for tickets...": "Pesquisar por tickets...",
  "New ticket": "Novo ticket",
  "Add your message...": "Adicionar sua mensagem...",
  "{0} is required": "{0} é obrigatório",
  "Invalid email": "Email inválido",
  "Sorry, an error occurred while creating the ticket":
    "Desculpe, ocorreu um erro ao criar o ticket",
  "Create ticket": "Criar ticket",
  "No tickets here": "Nenhum ticket aqui",
  "Once a new ticket is created, its status will be displayed here.":
    "Uma vez que um novo ticket é criado, seu status será exibido aqui.",
  "No tickets illustration": "Ilustração de tickets",
  Back: "Voltar",
  "Customer portal": "Portal do Cliente",
  "Stay updated on the status of all your company's support tickets.":
    "Fique atualizado sobre o status de todos os tickets da sua empresa.",
  "Sorry, an error occurred while sending the message":
    "Desculpe, ocorreu um erro ao enviar a mensagem",
  Send: "Enviar",
};

export default pt;
