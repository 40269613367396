import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "../styles/search-suggestions.module.css";
import categoryStyles from "../styles/category-tile.module.css";
import t from "../texts/t";

export type SuggestionsRef = {
  next: () => boolean;
  prev: () => boolean;
};

export type Suggestion = {
  url: string;
  name: string;
  categoryName: string;
  parentCategoryName?: string;
  publishDate: string;
  icon?: string;
};

export type SearchSuggestionsProps = {
  visible?: boolean;
  className?: string;
  title: string;
  suggestions: Suggestion[];
  language?: string;
  onClose?: () => unknown;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
};

const SearchSuggestions = forwardRef<SuggestionsRef, SearchSuggestionsProps>(
  function SearchSuggestions(
    {
      visible = true,
      className,
      title,
      suggestions,
      language,
      onClose,
      onFocus,
      onBlur,
    },
    ref
  ) {
    const linksContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        next: () => {
          if (!linksContainerRef.current?.children.length) return false;

          const nextElement =
            !document.activeElement ||
            document.activeElement.parentElement !== linksContainerRef.current
              ? linksContainerRef.current.children[0]
              : document.activeElement.nextElementSibling;

          if (nextElement?.tagName === "A") {
            (nextElement as HTMLAnchorElement).focus();
            return true;
          } else {
            return false;
          }
        },
        prev: () => {
          if (!linksContainerRef.current?.children.length) return false;

          const prevElement =
            !document.activeElement ||
            document.activeElement.parentElement !== linksContainerRef.current
              ? linksContainerRef.current.children[
                  linksContainerRef.current.children.length - 1
                ]
              : document.activeElement.previousElementSibling;

          if (prevElement?.tagName === "A") {
            (prevElement as HTMLAnchorElement).focus();
            return true;
          } else {
            return false;
          }
        },
      }),
      []
    );

    return (
      <div
        onFocus={onFocus}
        onBlur={onBlur}
        className={`${className || ""} ${styles.searchSuggestions} ${
          visible
            ? styles.searchSuggestionsVisible
            : styles.searchSuggestionsInvisible
        }`}
      >
        <div className={styles.searchSuggestionsTopLine}>
          <span className={styles.searchSuggestionsTopLineTitle} lang="en">
            {title}
          </span>
          {onClose && (
            <button
              className={styles.searchSuggestionsTopLineButton}
              onClick={onClose}
              lang={language}
            >
              {t("Close", language)}
            </button>
          )}
        </div>
        <div className={styles.searchSuggestionsList} ref={linksContainerRef}>
          {suggestions.map((suggestion) => (
            <a
              key={suggestion.url}
              className={styles.searchSuggestionsItem}
              href={suggestion.url}
            >
              <figure className={categoryStyles.categoryTileIcon}>
                {suggestion.icon || "📓"}
              </figure>
              <span>
                <span className={styles.searchSuggestionsItemTitle}>
                  {suggestion.name}
                </span>
                <span className={styles.searchSuggestionsItemSubtitle}>
                  {suggestion.parentCategoryName}
                  {suggestion.parentCategoryName && " ➔ "}
                  {suggestion.categoryName}
                </span>
              </span>
              <span
                className={styles.searchSuggestionsItemPublishDate}
                lang={language}
              >
                {suggestion.publishDate}
              </span>
            </a>
          ))}
          {!suggestions.length && (
            <p className={styles.searchSuggestionsEmptyMessage} lang={language}>
              {t("No results", language)}
            </p>
          )}
        </div>
      </div>
    );
  }
);

export default SearchSuggestions;
