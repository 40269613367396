import { GetServerSidePropsContext } from "next";
import api from "./api";
import { Article } from "./article-model";

export type { Article };

export default function loadTrendingArticles(
  companySlug: string,
  context?: GetServerSidePropsContext
) {
  return api<Article[] | undefined>({
    path: `/helpcenter-public/trending-articles/${companySlug}`,
    context,
  }).then((articles) => articles || []);
}
